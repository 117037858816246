import React from 'react';

import { rhythm } from '../utils/typography';

class Footer extends React.Component {
  render() {
    return (
      <footer
        style={{
          marginTop: rhythm(2.5),
          paddingTop: rhythm(1),
        }}
      >
        {/* <a
          href="https://mobile.twitter.com/teik_jun"
          target="_blank"
          rel="noopener noreferrer"
        >
          twitter
        </a>{' '}
        &bull;{' '} */}
        <a
          href="https://github.com/teikjun"
          target="_blank"
          rel="noopener noreferrer"
        >
          github
        </a>{' '}
        &bull;{' '}
        <a
          href="https://nus-cs3281.github.io/2021/"
          target="_blank"
          rel="noopener noreferrer"
        >
          cs3281
        </a>
      </footer>
    );
  }
}

export default Footer;
